import validators from "utils/validators";

export function validateFieldsWithoutErrorMessage(...fields) {
  const validatedFields = fields.reduce(
    (all, fieldName) => ({
      ...all,
      [fieldName]:
        validators[fieldName](document.getElementById(fieldName).value) || null,
    }),
    {}
  );

  return !Object.values(validatedFields).some(Boolean);
}

export function validateField(setErrors, ...fields) {
  console.log("Validating fields", fields);
  const validatedFields = fields.reduce(
    (all, fieldName) => ({
      ...all,
      [fieldName]:
        validators[fieldName](document.getElementById(fieldName).value) || null,
    }),
    {}
  );

  fields.forEach((field) => {
    const showError =
      validators[field](document.getElementById(field).value) !== undefined;
    document.getElementById(field).classList.toggle("error", showError);
  });

  setErrors(({ errors: currentErrors }) => {
    const newErrors = { ...(currentErrors || {}), ...validatedFields };

    return {
      errors: newErrors,
      activeError:
        newErrors[arguments[0]] ||
        Object.values(newErrors).filter(Boolean)[0] ||
        null,
    };
  });

  return !Object.values(validatedFields).some(Boolean);
}

export function validate(setErrors) {
  console.log("validate()");
  return validateField(setErrors, "name", "url", "amount");
}

export function normalizeURLField(url, setUrl) {
  setUrl(url.trim());
  if (/^https?:\/\//i.test(url) || url.length === 0) return;
  setUrl(`http://${url}`);
}
