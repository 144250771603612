import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      {/* <a href={helpers.etherscanUrl(contractAddress, TESTING)}>View contract</a> */}
      <a href="https://twitter.com/marckohlbrugge">Made by @marckohlbrugge</a>
      <a href="https://www.getrevue.co/profile/marckohlbrugge">
        Get notified of next drop
      </a>
    </div>
  );
}
