import React from "react";
import { scrollToCurrentPlayer } from "functions/pointer";

export default function Pointer({ currentAccount }) {
  return (
    <div
      className={`pointer ${!currentAccount && "hidden"}`}
      onClick={scrollToCurrentPlayer}
    >
      <div className="pointer__inner"></div>
    </div>
  );
}
