import React, { useEffect, useState, useRef } from "react";
import { ethers } from "ethers";
import "../App.css";

import Header from "components/common/Header";
import Footer from "components/common/Footer";
import Nav from "components/common/Nav";

import FallingCoin from "components/FallingCoin";
import Leaderboard from "components/Leaderboard";
import Pointer from "components/Pointer";
import TotalBalance from "components/TotalBalance";
import Form from "components/Form";

import abi from "utils/Game.json";
import { TESTING, INFURA_API_KEY, contractAddress } from "utils/const";

import { unlockAudio } from "functions/audio";
import {
  reconnectWalletIfPreviouslyConnected,
  updateBalance,
  pay,
  debouncedHandleScroll,
} from "functions/network";
import { getAllPlayers } from "functions/players";
import { handleScroll } from "functions/pointer";
import { validateFieldsWithoutErrorMessage } from "functions/validateFun";

export default function Home() {
  const [currentAccount, setCurrentAccount] = useState("");
  const [currentPlayer, setCurrentPlayer] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [{ errors, activeError }, setErrors] = useState({
    errors: {},
    activeError: null,
  });
  const [lifetimeBalance, setLifetimeBalance] = useState(0);
  const [txnHash, setTxnHash] = useState(null);
  const [lifetimeBalanceUSD, setLifetimeBalanceUSD] = useState(0);
  const [allPlayers, setAllPlayers] = useState({});
  const [coins, setCoins] = useState([]);

  const contractABI = abi.abi;
  const audio = new Audio();

  const contractRef = useRef(null);

  const payWrapper = (
    name,
    url,
    amount,
    setName,
    setUrl,
    setAmount,
    setIsProcessing
  ) => {
    pay(
      contractRef,
      amount,
      name,
      url,
      setTxnHash,
      setName,
      setAmount,
      setUrl,
      currentAccount,
      setCurrentAccount,
      setLifetimeBalance,
      setLifetimeBalanceUSD,
      setIsProcessing
    );
  };

  function tryEnableButton() {
    document
      .getElementById("payButton")
      .classList.toggle(
        "enabled",
        validateFieldsWithoutErrorMessage("name", "url", "amount")
      );
  }

  // Init
  useEffect(() => {
    // rainCoins(ethers.utils.parseEther("0.05"), setCoins);

    document.addEventListener("scroll", debouncedHandleScroll);
    window.addEventListener("resize", debouncedHandleScroll);

    document.body.addEventListener("click", () => {
      unlockAudio(audio);
    });
    document.body.addEventListener("touchstart", () => {
      unlockAudio(audio);
    });

    // const provider = new ethers.providers.CloudflareProvider();
    const provider = new ethers.providers.InfuraProvider(
      TESTING ? "rinkeby" : "homestead",
      INFURA_API_KEY
    );
    contractRef.current = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );

    reconnectWalletIfPreviouslyConnected(contractRef, setCurrentAccount);

    getAllPlayers(
      contractRef,
      setAllPlayers,
      audio,
      setCoins,
      updateBalance,
      setLifetimeBalance,
      setLifetimeBalanceUSD
    );
    updateBalance(contractRef, setLifetimeBalance, setLifetimeBalanceUSD);

    document.querySelector("h1").style.width = document.querySelector(
      "h1 > div:first-child"
    ).offsetWidth;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentAccount || allPlayers.length === 0) return;
    setCurrentPlayer(allPlayers[currentAccount.toLowerCase()]);
    handleScroll();
  }, [currentAccount, allPlayers]);

  useEffect(() => {
    handleScroll();
  }, [txnHash]);

  return (
    <div className="wrapper">
      <div className="crt"></div>

      <div className="screen">
        <Pointer currentAccount={currentAccount} />

        {Object.values(coins).map((coin, index) => {
          return <FallingCoin coin={coin} key={index} />;
        })}

        <Nav
          contractRef={contractRef}
          setCurrentAccount={setCurrentAccount}
          currentAccount={currentAccount}
        />

        <Header name="Pay.Game" slogan="Pay-to-play on the blockchain" />

        <div className="content">
          <Form
            setErrors={setErrors}
            tryEnableButton={tryEnableButton}
            pay={payWrapper}
            activeError={activeError}
            txnHash={txnHash}
            currentPlayer={currentPlayer}
          />

          <Leaderboard
            allPlayers={allPlayers}
            currentAccount={currentAccount}
          />

          <img src="coin.gif" alt="" className="spinnerCoin pixelart" />

          <TotalBalance
            lifetimeBalance={lifetimeBalance}
            lifetimeBalanceUSD={lifetimeBalanceUSD}
          />

          <Footer />
        </div>
      </div>
    </div>
  );
}
