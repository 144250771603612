import React, { useEffect } from "react";
import Player from "./Player";
import { playersSortedAndRanked } from "functions/players";
import { handleScroll } from "functions/pointer";

export default function Leaderboard({ allPlayers, currentAccount }) {
  const [mode, setMode] = React.useState("score");
  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 500);
  }, [allPlayers]);

  useEffect(() => {
    handleScroll();
  }, [mode]);

  return (
    <>
      {" "}
      <div className="leaderboard">
        <div className="leaderboard__header">
          <div className="leaderboard__header__right">RANK</div>
          <div>NAME</div>

          {mode === "score" ? (
            <div
              className="leaderboard__header__right"
              onClick={() => setMode("recent")}
            >
              Score
            </div>
          ) : (
            <div
              className="leaderboard__header__right"
              onClick={() => setMode("score")}
            >
              Newest
            </div>
          )}
        </div>

        {playersSortedAndRanked(allPlayers, mode).map((player) => {
          const isMine = currentAccount.toLowerCase() === player.addr;

          return (
            <Player
              player={player}
              rank={player.rank}
              isMine={isMine}
              key={player.addr}
            />
          );
        })}
      </div>
    </>
  );
}
