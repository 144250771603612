import React from "react";

export default function Header({ name, slogan }) {
  return (
    <h1 className="header">
      <div>
        <span>{name}</span> {slogan}&nbsp;
      </div>
      <div>
        <span>{name}</span> {slogan}&nbsp;
      </div>
    </h1>
  );
}
