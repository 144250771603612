module.exports = {
  name: function (value) {
    if (value.trim().length === 0) {
      return "Please enter a name";
    } else if (value.length > 16) {
      return "Please shorten name to max 16 bytes";
    } else if (!/^[\x20-\x7E]*$/.test(value)) {
      return "No special characters please";
    }
  },

  url: function (value) {
    if (value.length > 128) {
      return "Please shorten link to max 128 bytes";
    }
  },

  amount: function (value) {
    if (value === "" || isNaN(value)) {
      return "Please enter an amount";
    } else if (parseFloat(value) < 0.01) {
      return "Minimum is 0.01 ETH";
    }
  },
};
