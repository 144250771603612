import React, { useState, useEffect } from "react";
import helpers from "utils/helpers";
import { TESTING } from "utils/const";

import { disablePulsating } from "functions/animation";
import { scrollToCurrentPlayer } from "functions/pointer";

import {
  validate,
  normalizeURLField,
  validateField,
} from "functions/validateFun";

export default function Form({
  setErrors,
  tryEnableButton,
  pay,
  activeError,
  txnHash,
  currentPlayer,
}) {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [amount, setAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!currentPlayer) return;
    if (name === "") setName(currentPlayer.formattedName);
    if (url === "") setUrl(currentPlayer.url);

    scrollToCurrentPlayer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer]);

  const inputChangeHandler = (e) => {
    validateField(setErrors, e.target.name);
    tryEnableButton();
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;

      case "url":
        setUrl(e.target.value);
        break;

      case "amount":
        setAmount(e.target.value);
        break;

      default:
        console.log("input not recognized");
        break;
    }
  };

  return (
    <div className="form">
      <div className="form__fields">
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={inputChangeHandler}
          onFocus={disablePulsating}
          placeholder="NAME"
          disabled={isProcessing}
          className="input--pulsating"
        />
        <input
          type="url"
          id="url"
          value={url}
          name="url"
          onChange={inputChangeHandler}
          onFocus={disablePulsating}
          disabled={isProcessing}
          onBlur={() => {
            normalizeURLField(url, setUrl);
          }}
          placeholder="URL"
        />
        <input
          type="number"
          min="0.01"
          step="0.01"
          id="amount"
          value={amount}
          disabled={isProcessing}
          name="amount"
          onChange={inputChangeHandler}
          onFocus={disablePulsating}
          placeholder={currentPlayer ? "ETH" : "SCORE"}
        />

        <button
          id="payButton"
          type="button"
          disabled={isProcessing}
          onClick={async (e) => {
            console.table(name, amount, url, setName, setAmount, setUrl);
            validate(setErrors) &&
              (await pay(
                name,
                url,
                amount,
                setName,
                setUrl,
                setAmount,
                setIsProcessing
              ));
          }}
        >
          {currentPlayer ? (isProcessing ? "WAIT" : "ADD") : "PAY"}
        </button>
      </div>

      {activeError && <ErrorMessage activeError={activeError} />}

      {txnHash && <Processing txnHash={txnHash} />}
    </div>
  );
}

const ErrorMessage = ({ activeError }) => {
  return <div className="errorMessage">{activeError}</div>;
};

const Processing = ({ txnHash }) => {
  return (
    <a
      href={helpers.etherscanTxnUrl(txnHash, TESTING)}
      className="transactionMessage"
    >
      Processing transaction {helpers.truncateMiddle(txnHash, 6)}
    </a>
  );
};
