function scrollToCurrentPlayer() {
  console.log("scrollToCurrentPlayer()");
  document.querySelector(".player--current > div").scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
}

function setPointerLocation(pointer, target) {
  const targetBox = target.getBoundingClientRect();
  const targetY = targetBox.top + targetBox.height / 2;
  const newPointerY = `clamp(3vw, ${targetY}px, calc(${window.innerHeight}px - 4vw))`;
  const halfOfPointerHeight = "1.4vw";

  pointer.style.setProperty(
    "--top",
    `calc(${window.scrollY}px + ${newPointerY} - ${halfOfPointerHeight})`
  );
}

function setPointerRotation(pointer, target) {
  const targetBox = target.getBoundingClientRect();
  const targetY = targetBox.top + targetBox.height / 2;
  const targetX = targetBox.left;

  const pointerBox = pointer.getBoundingClientRect();
  const centerPoint = window.getComputedStyle(pointer).transformOrigin;

  const centers = centerPoint.split(" ");
  const centerY = pointerBox.top + parseInt(centers[1]);
  const centerX = pointerBox.left + parseInt(centers[0]);

  const dx = targetX - centerX;
  const dy = targetY - centerY;

  const theta = Math.atan2(dy, dx);
  let degree = theta * (180 / Math.PI) * 1;
  targetBox.degree = degree;

  if (degree > 90 || degree < -90) {
    console.error("pointer rotation is not correct");
  }

  if (degree === 180) {
    degree = 0;
  }

  pointer.style.setProperty("--degree", degree + "deg");
}

function handleScroll() {
  const target = document.querySelector(".player--current .player__rank");

  const pointer = document.querySelector(".pointer");

  if (!target) {
    pointer?.setAttribute("hidden", "");
    return;
  } else {
    pointer.removeAttribute("hidden");
  }

  setPointerLocation(pointer, target);
  setPointerRotation(pointer, target);
}

module.exports = {
  scrollToCurrentPlayer,
  setPointerLocation,
  setPointerRotation,
  handleScroll,
};
