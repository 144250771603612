const COINS_PER_ETH = process.env.REACT_APP_COINS_PER_ETH;
const TESTING = process.env.REACT_APP_TESTING.toLowerCase() == "true";
const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const openseaURL = process.env.REACT_APP_OPENSEA_URL;

module.exports = {
  COINS_PER_ETH,
  TESTING,
  INFURA_API_KEY,
  contractAddress,
  openseaURL,
};
