import { ethers } from "ethers";
import { COINS_PER_ETH } from "utils/const";

function disablePulsating() {
  document.getElementById("name").classList.remove("input--pulsating");
}

function rainCoins(gwei, setCoins) {
  let count = (+ethers.utils.formatEther(gwei)).toFixed(2) * COINS_PER_ETH;

  let _coins = [];

  for (let i = 0; i < count; ++i) {
    _coins.push({
      left: `${parseInt(Math.random() * 100)}vw`,
      delay: `${parseInt(Math.random() * 1000)}ms`,
      duration: `${200 + parseInt(Math.random() * 800)}ms`,
      flip: Math.random() > 0.5,
    });
  }

  setCoins(_coins);
}

export { disablePulsating, rainCoins };
