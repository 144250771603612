import React from "react";

export default function FallingCoin({ coin }) {
  const key = [coin.left, coin.delay, coin.duration, coin.flip].join("_");

  const style = {
    "--left": coin.left,
    "--delay": coin.delay,
    "--duration": coin.duration,
    "--scaleX": coin.flip ? "-100%" : "100%",
  };

  return (
    <img
      src="coin.gif"
      className="fallingCoin pixelart"
      style={style}
      key={key}
      alt=""
    />
  );
}
