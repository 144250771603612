function unlockAudio(audio) {
  audio.src =
    "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

  const promise = audio.play();

  if (promise !== undefined) {
    promise.catch((error) => {
      console.log("Error while playing sound", error);
    });
  }

  document.body.removeEventListener("click", unlockAudio);
  document.body.removeEventListener("touchstart", unlockAudio);
}

function playSound(audio) {
  audio.src = "Coin_1.wav";
  audio.currentTime = 0;
  const promise = audio.play();

  if (promise !== undefined) {
    promise.catch((error) => {
      console.log("Error while playing sound", error);
    });
  }
}

function getAudioContext() {
  if (window.webkitAudioContext) {
    return new window.webkitAudioContext();
  } else {
    return new window.AudioContext();
  }
}

function playBackgroundMusic(sourceRef) {
  const audioCtx = getAudioContext();
  sourceRef.current = audioCtx.createBufferSource();
  const request = new XMLHttpRequest();

  request.open("GET", "bg.mp3", true);
  request.responseType = "arraybuffer";

  request.onload = function () {
    var audioData = request.response;

    audioCtx.decodeAudioData(
      audioData,
      function (buffer) {
        const myBuffer = buffer;
        sourceRef.current.buffer = myBuffer;
        sourceRef.current.connect(audioCtx.destination);
        sourceRef.current.loop = true;
      },

      function (e) {
        console.log("Error with decoding audio data" + e.err);
      }
    );
  };

  request.send();
  sourceRef.current.start(0);
}

function stopBackgroundMusic(sourceRef) {
  if (sourceRef.current) sourceRef.current.stop();
}

module.exports = {
  unlockAudio,
  playSound,
  playBackgroundMusic,
  stopBackgroundMusic,
};
