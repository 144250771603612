module.exports = {
  truncateMiddle: function (string, count = 3) {
    return `${string.substring(0, count)}…${string.substring(
      string.length - count
    )}`;
  },

  ordinalize: function (integer) {
    if (integer === 1) {
      return "1st";
    } else if (integer === 2) {
      return "2nd";
    } else if (integer === 3) {
      return "3rd";
    } else {
      return `${integer}th`;
    }
  },

  etherscanUrl: function (address, testing = false) {
    const subdomain = testing ? "rinkeby." : "";
    return `https://${subdomain}etherscan.io/address/${address}`;
  },

  etherscanTxnUrl: function (txn, testing = false) {
    const subdomain = testing ? "rinkeby." : "";
    return `https://${subdomain}etherscan.io/tx/${txn}`;
  },

  debounce: function (func, wait, immediate) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  },
};
