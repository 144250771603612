import React, { useState, useRef, useEffect } from "react";
import { TESTING, contractAddress, openseaURL } from "utils/const";
import { connectWallet, disconnectWallet } from "functions/network";
import { playBackgroundMusic, stopBackgroundMusic } from "functions/audio";
import helpers from "utils/helpers";

export default function Nav({
  currentAccount,
  contractRef,
  setCurrentAccount,
}) {
  const [isSoundEnabled, setSoundEnabled] = useState(false);

  const sourceRef = useRef();

  useEffect(() => {
    isSoundEnabled
      ? playBackgroundMusic(sourceRef)
      : stopBackgroundMusic(sourceRef);
  }, [isSoundEnabled]);

  function openAbout(e) {
    e.preventDefault();

    const w = 400;
    const h = 600;

    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const left = (width - w) / 2 + dualScreenLeft;
    const top = (height - h) / 2 + dualScreenTop;

    const win = window.open(
      e.target.href,
      "about",
      `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    top=${top},
                                    left=${left},
                                    width=${w},
                                    height=${h}`
    );

    if (window.focus) win.focus();
  }

  return (
    <div className="nav">
      <a href="/about" onClick={openAbout}>
        about
      </a>
      <a href="https://discord.gg/fkXr8GvAnY" target="_blank">
        Discord
      </a>
      <a href={helpers.etherscanUrl(contractAddress, TESTING)} target="_blank">
        Contract
      </a>
      <a href={openseaURL}>OPENSEA</a>
      {/* <a href="https://twitter.com/marckohlbrugge" target="_blank">Made By</a> */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setSoundEnabled(!isSoundEnabled);
        }}
      >
        Music
        {isSoundEnabled ? <span> ON</span> : <span> OFF</span>}
      </a>

      {currentAccount ? (
        <a
          href="#"
          onClick={() => {
            disconnectWallet(contractRef, setCurrentAccount);
          }}
        >
          Connected {helpers.truncateMiddle(currentAccount)}
        </a>
      ) : (
        <a
          href="#"
          onClick={() => {
            connectWallet(contractRef, setCurrentAccount);
          }}
        >
          Connect
        </a>
      )}
    </div>
  );
}
