import React from "react";
const helpers = require("utils/helpers");

export default function Player({ player, rank, isMine }) {
  let classes = ["player"];
  classes.push(`player--${helpers.ordinalize(rank)}`);
  if (isMine) classes.push("player--current");
  if (player.flicker) classes.push("player--flicker");

  return (
    <div key={player.addr} className={classes.join(" ")}>
      <div className="player__rank">{helpers.ordinalize(rank)}</div>
      <div>
        <a href={player.url} target="_blank" rel="noopener noreferrer">
          {player.formattedName}
        </a>
      </div>
      <div className="player__score">
        <a href={player.etherscan} target="_blank" rel="noopener noreferrer">
          {player.formattedScore}
        </a>
      </div>
    </div>
  );
}
